
import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import RenderedRichText from '../components/richText'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SEO } from '../components/seo'

const Page = ({ data, location }) => {
  return (
    <Layout location={location}>
      {data.contentfulPage.heroImage &&
        <GatsbyImage
          image={data.contentfulPage.heroImage.gatsbyImageData}
          alt={data.contentfulPage.heroImage.description}
          loading="eager"
          className="responsive-gatsby-image"
          />}
      <h1 className="text-center">{data.contentfulPage.title}</h1>
      <hr className="my-8" />
      <RenderedRichText richText={data.contentfulPage.bodyText} />
    </Layout>
  )
}

export const query = graphql`
  query ($urlPath: String) {
    contentfulPage(urlPath: {eq: $urlPath}) {
      heroImage {
        gatsbyImageData(width: 1000, placeholder: TRACED_SVG)
        description
        contentful_id
        __typename
      }
      title
      bodyText {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 1000, placeholder: BLURRED)
            file {
              contentType
              url
            }
            __typename
          }
        }
      }
      urlPath
    }
  }
`

export default Page

export const Head = ({ data }) => (
  <SEO title={data.contentfulPage.title} />
)

